import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://marfa.app.1life.com',
  myoneServer: 'https://marfa.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-marfa',
  },
  stripePublishableKey: 'pk_test_8WxlUuvntFBOdmluGdlAuZTF00aeSGUqr4',
  launchDarklyClientId: '605033c1a544ec0cd24ff91b',
};
